import { Export } from "@amcharts/amcharts4/core";

export const TOGGLE_SIDEBAR_MENU = "TOGGLE_SIDEBAR_MENU"; // Toggle sidebar

export const TOGGLE_CTC_FULL_HIVE_HENDER_BTN = "TOGGLE_CTC_FULL_HIVE_HENDER_BTN"; // Toggle btn For Only practice User 48
export const TOGGLE_CTC_SCHEDULING_ONLY_BTN = "TOGGLE_CTC_SCHEDULING_ONLY_BTN";  // Toggle btn For Only practice User 48
export const TOGGLE_CTC_CLICK_TO_CALL_BTN = "TOGGLE_CTC_CLICK_TO_CALL_BTN";     // Toggle btn For Only practice User 48
export const CTT_SELECTED_TOGGLE_PAYLOAD = "CTT_SELECTED_TOGGLE_PAYLOAD";

export const BUSINESS_HOURS_BTN = 'BUSINESS_HOURS_BTN'

export const TOGGLE_TOP_HEADER = "TOGGLE_TOP_HEADER"; // Toggle sidebar


export const DOTS_OPTION = "DOTS_OPTION"; // Dots Option

export const TOGGLE_HEADER_BTN = "TOGGLE_HEADER_BTN"; // Toggle header btn

// Get text center id
export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const AUTHENTICATION_RESPONSE = "AUTHENTICATION_RESPONSE";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";

// Get Bss Users View Options
export const GET_BSS_USERS_VIEW_RESPONSE = "GET_BSS_USERS_VIEW_RESPONSE";

// Get text center id
export const GET_TEXT_CENTER_REQUEST = "GET_TEXT_CENTER_REQUEST";
export const GET_TEXT_CENTER_RESPONSE = "GET_TEXT_CENTER_RESPONSE";
export const GET_TEXT_CENTER_ERROR = "GET_TEXT_CENTER_ERROR";

// Get Leads Option Array
export const GET_COMMON_LEADS_OPTION_REQUEST = "GET_COMMON_LEADS_OPTION_REQUEST";
export const GET_COMMON_LEADS_OPTION_RESPONSE = "GET_COMMON_LEADS_OPTION_RESPONSE";
export const GET_COMMON_LEADS_OPTION_ERROR = "GET_COMMON_LEADS_OPTION_ERROR";

// Web Pages Option Array
export const GET_COMMON_WEB_PAGES_OPTION_RESPONSE = "GET_COMMON_WEB_PAGES_OPTION_RESPONSE";

// Insurance Plans Option Array
export const GET_COMMON_INSURANCE_PLANS_OPTION_RESPONSE = "GET_COMMON_INSURANCE_PLANS_OPTION_RESPONSE";
export const GET_ALL_OPTION_RESPONSE = "GET_ALL_OPTION_RESPONSE";

// get Procedures option array
export const GET_HEADER_PROCEDURES_OPTION_RESPONSE = "GET_HEADER_PROCEDURES_OPTION_RESPONSE";
export const GET_HEADER_PROCEDURES_OPTION_ERROR = "GET_HEADER_PROCEDURES_OPTION_ERROR";

// Initial Api Call
export const GET_CHART_ANALYTICS_REQUEST = "GET_CHART_ANALYTICS_REQUEST";
export const GET_CHART_ANALYTICS_RESPONSE = "GET_CHART_ANALYTICS_RESPONSE";
export const GET_CHART_ANALYTICS_ERROR = "GET_CHART_ANALYTICS_ERROR";

// Get UTM toggle Option Array 
export const GET_UTM_OPTIONS_RESPONSE = "GET_UTM_OPTIONS_RESPONSE";

// ! --------------------------------- Line Chart ---------------------------------
// MultiLineChart Initial Api Call
export const GET_MULTILINE_CHART_ANALYTICS_REQUEST = "GET_MULTILINE_CHART_ANALYTICS_REQUEST";
export const GET_MULTILINE_CHART_ANALYTICS_RESPONSE = "GET_MULTILINE_CHART_ANALYTICS_RESPONSE";
export const GET_MULTILINE_CHART_ANALYTICS_ERROR = "GET_MULTILINE_CHART_ANALYTICS_ERROR";

// Showed & Upcoming Consult Report
export const GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_REQUEST = "GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_REQUEST";
export const GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_RESPONSE = "GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_RESPONSE";
export const GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_ERROR = "GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_ERROR";
export const TOGGLE_COMBINE_LEADS_BTN = "TOGGLE_COMBINE_LEADS_BTN"; // Toggle Combine Leads btn

// Showed & Upcoming Consult Appointment
export const GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_REQUEST = "GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_REQUEST";
export const GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_RESPONSE = "GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_RESPONSE";
export const GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_ERROR = "GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_ERROR";
export const TOGGLE_COMBINE_LEADS_APPOINTMENT_BTN = "TOGGLE_COMBINE_LEADS_APPOINTMENT_BTN"; // Toggle Combine Leads btn


// daily_open_action_items_report
export const GET_DAILY_OPEN_ACTION_ITEMS_REPORT_REQUEST = "GET_DAILY_OPEN_ACTION_ITEMS_REPORT_REQUEST";
export const GET_DAILY_OPEN_ACTION_ITEMS_REPORT_RESPONSE = "GET_DAILY_OPEN_ACTION_ITEMS_REPORT_RESPONSE";
export const GET_DAILY_OPEN_ACTION_ITEMS_REPORT_ERROR = "GET_DAILY_OPEN_ACTION_ITEMS_REPORT_ERROR";


// 
export const GET_AVALIABLE_APPOINTMENT_LINE_CHART_REQUEST = "GET_AVALIABLE_APPOINTMENT_LINE_CHART_REQUEST";
export const GET_AVALIABLE_APPOINTMENT_LINE_CHART_RESPONSE = "GET_AVALIABLE_APPOINTMENT_LINE_CHART_RESPONSE";
export const GET_AVALIABLE_APPOINTMENT_LINE_CHART_ERROR = "GET_AVALIABLE_APPOINTMENT_LINE_CHART_ERROR";

// Get Leads Option Array

// ! Multivalue Axis data (multiple y axis)
export const GET_WEBSITE_CONVERSION_REQUEST = "GET_WEBSITE_CONVERSION_REQUEST";
export const GET_WEBSITE_CONVERSION_RESPONSE = "GET_WEBSITE_CONVERSION_RESPONSE";
export const GET_WEBSITE_CONVERSION_ERROR = "GET_WEBSITE_CONVERSION_ERROR";

//Get Alerts Data
export const GET_ALERTS_REQUEST = "GET_ALERTS_REQUEST"
export const GET_ALERTS_RESPONSE = "GET_ALERTS_RESPONSE";
export const GET_ALERTS_ERROR = "GET_ALERTS_ERROR"
export const GET_ALERTS_COUNT = "GET_ALERTS_COUNT"

// ! --------------------------------- Pyramid Chart ---------------------------------
// ScheduleFirst Pyramid Chart Initial Api Call
export const GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST = "GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST";
export const GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE = "GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE";
export const GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR = "GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR";

// InsuranceFirst Pyramid Chart Initial Api Call
export const GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST = "GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST";
export const GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE = "GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE";
export const GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR = "GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR";

// ! --------------------------------- Gauge Chart ---------------------------------
// Get Practice Location Api Call
export const GET_PRACTICE_LOCATIONS_API_REQUEST = "GET_PRACTICE_LOCATIONS_API_REQUEST"
export const GET_PRACTICE_LOCATIONS_API_RESPONSE = "GET_PRACTICE_LOCATIONS_API_RESPONSE"
export const GET_PRACTICE_LOCATIONS_API_ERROR = "GET_PRACTICE_LOCATIONS_API_ERROR"
// Get Surgeons Api Call
export const GET_SURGEONS_API_REQUEST = "GET_SURGEONS_API_REQUEST"
export const GET_SURGEONS_API_RESPONSE = "GET_SURGEONS_API_RESPONSE"
export const GET_SURGEONS_API_ERROR = "GET_SURGEONS_API_ERROR"
// Get Gauge Chart Api Call
export const GET_GAUGECHART_API_REQUEST = "GET_GAUGECHART_API_REQUEST"
export const GET_GAUGECHART_API_RESPONSE = "GET_GAUGECHART_API_RESPONSE"
export const GET_GAUGECHART_API_ERROR = "GET_GAUGECHART_API_ERROR"

// ! --------------------------------- Heat Map Chart ---------------------------------
// Booked Appointment Heat Map Chart Api Call 
export const GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST = "GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST";
export const GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE = "GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE";
export const GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR = "GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR";

export const GET_SELECTED_LOCATION_BOOKED_RESPONSE = "GET_SELECTED_LOCATION_BOOKED_RESPONSE";
// Get Leads Option Array
export const GET_LEADS_OPTION_BOOKED_REQUEST = "GET_LEADS_OPTION_BOOKED_REQUEST";
export const GET_LEADS_OPTION_BOOKED_RESPONSE = "GET_LEADS_OPTION_BOOKED_RESPONSE";
export const GET_LEADS_OPTION_BOOKED_ERROR = "GET_LEADS_OPTION_BOOKED_ERROR";

// Showed Appointment Heat Map Chart Api Call 
export const GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST = "GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST";
export const GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE = "GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE";
export const GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR = "GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR";

export const GET_SELECTED_LOCATION_SHOWED_RESPONSE = "GET_SELECTED_LOCATION_SHOWED_RESPONSE";
// Get Leads Option Array
export const GET_LEADS_OPTION_SHOWED_REQUEST = "GET_LEADS_OPTION_SHOWED_REQUEST";
export const GET_LEADS_OPTION_SHOWED_RESPONSE = "GET_LEADS_OPTION_SHOWED_RESPONSE";
export const GET_LEADS_OPTION_SHOWED_ERROR = "GET_LEADS_OPTION_SHOWED_ERROR";

// Show Rate Appointment Heat Map Chart Api Call 
export const GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST = "GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST";
export const GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE = "GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE";
export const GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR = "GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR";

export const GET_SELECTED_LOCATION_SHOW_RATE_RESPONSE = "GET_SELECTED_LOCATION_SHOW_RATE_RESPONSE";
// Get Leads Option Array
export const GET_LEADS_OPTION_SHOW_RATE_REQUEST = "GET_LEADS_OPTION_SHOW_RATE_REQUEST";
export const GET_LEADS_OPTION_SHOW_RATE_RESPONSE = "GET_LEADS_OPTION_SHOW_RATE_RESPONSE";
export const GET_LEADS_OPTION_SHOW_RATE_ERROR = "GET_LEADS_OPTION_SHOW_RATE_ERROR";

// ! --------------------------------- Funnel Chart ---------------------------------
// Insurance Report Funnel Chart Api Call
export const GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_REQUEST = "GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_REQUEST";
export const GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_RESPONSE = "GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_RESPONSE";
export const GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_ERROR = "GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_ERROR";

// Get Leads Option Array
export const GET_LEADS_OPTION_INSURANCE_FUNNEL_REQUEST = "GET_LEADS_OPTION_INSURANCE_FUNNEL_REQUEST";
export const GET_LEADS_OPTION_INSURANCE_FUNNEL_RESPONSE = "GET_LEADS_OPTION_INSURANCE_FUNNEL_RESPONSE";
export const GET_LEADS_OPTION_INSURANCE_FUNNEL_ERROR = "GET_LEADS_OPTION_INSURANCE_FUNNEL_ERROR";

// ! --------------------------------- Phone Flow ---------------------------------
// ScheduleFirst Pyramid Chart Initial Api Call
export const GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST = "GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST";
export const GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE = "GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE";
export const GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR = "GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR";

// InsuranceFirst Pyramid Chart Initial Api Call
export const GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST = "GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST";
export const GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE = "GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE";
export const GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR = "GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR";

// ! -------------------------------- Google Analytics --------------------------------
export const GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_REQUEST = "GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_REQUEST";
export const GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_RESPONSE = "GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_RESPONSE";
export const GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_ERROR = "GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_ERROR";

// ! --------------------------------- Phone Flow ---------------------------------
// ScheduleFirst Pyramid Chart Initial Api Call
export const GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST = "GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST";
export const GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE = "GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE";
export const GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR = "GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR";

// InsuranceFirst Pyramid Chart Initial Api Call
export const GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST = "GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST";
export const GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE = "GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE";
export const GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR = "GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR";

// ! --------------------------------- Bar Chart ---------------------------------
// InsuranceFirst Pyramid Chart Initial Api Call
export const GET_CLICKED_SCHEDULING_BAR_CHART_REQUEST = "GET_CLICKED_SCHEDULING_BAR_CHART_REQUEST";
export const GET_CLICKED_SCHEDULING_BAR_CHART_RESPONSE = "GET_CLICKED_SCHEDULING_BAR_CHART_RESPONSE";
export const GET_CLICKED_SCHEDULING_BAR_CHART_ERROR = "GET_CLICKED_SCHEDULING_BAR_CHART_ERROR";

export const GET_SELECTED_LOCATION_CLICKED_BAR_RESPONSE = "GET_SELECTED_LOCATION_CLICKED_BAR_RESPONSE";

// ! --------------------------------- Stacked Column Chart ---------------------------------
// Get Showed Consults By Lead Source 100%
export const GET_FILLED_VS_UNFILLED_APPOINTMENT_REQUEST = "GET_FILLED_VS_UNFILLED_APPOINTMENT_REQUEST";
export const GET_FILLED_VS_UNFILLED_APPOINTMENT_RESPONSE = "GET_FILLED_VS_UNFILLED_APPOINTMENT_RESPONSE";
export const GET_FILLED_VS_UNFILLED_APPOINTMENT_ERROR = "GET_FILLED_VS_UNFILLED_APPOINTMENT_ERROR";

export const GET_SELECTED_LOCATION_FILLED_VS_UNFILLED_RESPONSE = "GET_SELECTED_LOCATION_FILLED_VS_UNFILLED_RESPONSE";

// Get Showed Consults By Lead Source
export const GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_REQUEST = "GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_REQUEST";
export const GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_RESPONSE = "GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_RESPONSE";
export const GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_ERROR = "GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_ERROR";

export const GET_SELECTED_LOCATION_SHOWED_CONSULTS_BY_LEAD_RESPONSE = "GET_SELECTED_LOCATION_SHOWED_CONSULTS_BY_LEAD_RESPONSE";

// Get Upcoming Consults By Lead Source
export const GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_REQUEST = "GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_REQUEST";
export const GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_RESPONSE = "GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_RESPONSE";
export const GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_ERROR = "GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_ERROR";

export const GET_SELECTED_LOCATION_UPCOMING_CONSULTS_BY_LEAD_RESPONSE = "GET_SELECTED_LOCATION_UPCOMING_CONSULTS_BY_LEAD_RESPONSE";

// Get Showed Vs NoShow Vs Cancelled Column Chart
export const GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_REQUEST = "GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_REQUEST";
export const GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_RESPONSE = "GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_RESPONSE";
export const GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_ERROR = "GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_ERROR";

export const GET_SELECTED_LOCATION_SHOWED_VS_NOSHOW_VS_CANCELLED_RESPONSE = "GET_SELECTED_LOCATION_SHOWED_VS_NOSHOW_VS_CANCELLED_RESPONSE";

// ! -------------------------------- Pie Chart --------------------------------
// Showed Consults
export const GET_SHOWED_CONSULTS_PIE_CHART_REQUEST = "GET_SHOWED_CONSULTS_PIE_CHART_REQUEST";
export const GET_SHOWED_CONSULTS_PIE_CHART_RESPONSE = "GET_SHOWED_CONSULTS_PIE_CHART_RESPONSE";
export const GET_SHOWED_CONSULTS_PIE_CHART_ERROR = "GET_SHOWED_CONSULTS_PIE_CHART_ERROR";

//filled_vs_unfilled_future_appointment_slots_PieChart
export const GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_REQUEST = "GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_REQUEST";
export const GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_RESPONSE = "GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_RESPONSE";
export const GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_ERROR = "GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_ERROR";

export const GET_SELECTED_LOCATION_SHOWED_CONSULTS_PIE_CHART_RESPONSE = "GET_SELECTED_LOCATION_SHOWED_CONSULTS_PIE_CHART_RESPONSE";

// Upcoming Consults
export const GET_UPCOMING_CONSULTS_PIE_CHART_REQUEST = "GET_UPCOMING_CONSULTS_PIE_CHART_REQUEST";
export const GET_UPCOMING_CONSULTS_PIE_CHART_RESPONSE = "GET_UPCOMING_CONSULTS_PIE_CHART_RESPONSE";
export const GET_UPCOMING_CONSULTS_PIE_CHART_ERROR = "GET_UPCOMING_CONSULTS_PIE_CHART_ERROR";

export const GET_SELECTED_LOCATION_UPCOMING_CONSULTS_PIE_CHART_RESPONSE = "GET_SELECTED_LOCATION_UPCOMING_CONSULTS_PIE_CHART_RESPONSE";

// ! -------------------------------- Click To 1 -------------------------------
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_REQUEST = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_REQUEST";
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_RESPONSE = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_RESPONSE";
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_ERROR = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_ERROR";

// ! -------------------------------- Click To 2  -------------------------------
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_REQUEST = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_REQUEST";
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_RESPONSE = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_RESPONSE";
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_ERROR = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_ERROR";

// ! -------------------------------- Click To 3  -------------------------------
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_REQUEST = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_REQUEST";
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_RESPONSE = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_RESPONSE";
export const GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_ERROR = "GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_ERROR";

// ! -------------------------------- Click To 4  -------------------------------
export const GET_CLICK_TO_CALL_FUNNEL_REQUEST = "GET_CLICK_TO_CALL_FUNNEL_REQUEST";
export const GET_CLICK_TO_CALL_FUNNEL_RESPONSE = "GET_CLICK_TO_CALL_FUNNEL_RESPONSE";
export const GET_CLICK_TO_CALL_FUNNEL_ERROR = "GET_CLICK_TO_CALL_FUNNEL_ERROR";

// ! -------------------------------- Click To 5  -------------------------------

export const GET_CLICK_TO_CALL_WEBSITE_CONVERSION = "GET_CLICK_TO_CALL_WEBSITE_CONVERSION"
export const GET_CLICK_TO_CALL_WEBSITE_RESPONSE = "GET_CLICK_TO_CALL_WEBSITE_RESPONSE"
export const GET_CLICK_TO_CALL_WEBSITE_ERROR = "GET_CLICK_TO_CALL_WEBSITE_ERROR"




// ! -------------------------------- FOLLOW_UP_IMPACT_REPORT_CHART -------------------------------
export const GET_FOLLOW_UP_IMPACT_REPORT_CHART_REQUEST = "GET_FOLLOW_UP_IMPACT_REPORT_CHART_REQUEST";
export const GET_FOLLOW_UP_IMPACT_REPORT_CHART_RESPONSE = "GET_FOLLOW_UP_IMPACT_REPORT_CHART_RESPONSE";
export const GET_FOLLOW_UP_IMPACT_REPORT_CHART_ERROR = "GET_FOLLOW_UP_IMPACT_REPORT_CHART_ERROR";

// ! -------------------------------- open_action_item_average_turn_around_time --------------------------------
export const GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_REQUEST = "GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_REQUEST"
export const GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_RESPONSE = "GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_RESPONSE"
export const GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_ERROR = "GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_ERROR"

// ! -------------------------------- open_action_items_resolved_by_user --------------------------------
export const GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST = "GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST";
export const GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_RESPONSE = "GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_RESPONSE";
export const GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_ERROR = "GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_ERROR";

// ! -------------------------------- facebook_ads_cost_per_conversion ------------------------------
export const GET_FACEBOOK_ADS_COST_PER_CONVERSION_REQUEST = "GET_FACEBOOK_ADS_COST_PER_CONVERSION_REQUEST";
export const GET_FACEBOOK_ADS_COST_PER_CONVERSION_RESPONS = "GET_FACEBOOK_ADS_COST_PER_CONVERSION_RESPONS";
export const GET_FACEBOOK_ADS_COST_PER_CONVERSION_ERROR = "GET_FACEBOOK_ADS_COST_PER_CONVERSION_ERROR";

// ! -------------------------------- facebook_ads_cost_per_conversion_funnel ------------------------------

export const GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST = "GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST";
export const GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS = "GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS";
export const GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_ERROR = "GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_ERROR";


// ! -------------------------------- google_ads_cost_per_conversion ------------------------------
export const GET_GOOGLE_ADS_COST_PER_CONVERSION_REQUEST = "GET_GOOGLE_ADS_COST_PER_CONVERSION_REQUEST";
export const GET_GOOGLE_ADS_COST_PER_CONVERSION_RESPONS = "GET_GOOGLE_ADS_COST_PER_CONVERSION_RESPONS";
export const GET_GOOGLE_ADS_COST_PER_CONVERSION_ERROR = "GET_GOOGLE_ADS_COST_PER_CONVERSION_ERROR";

// ! -------------------------------- google_ads_cost_per_conversion_funnel ------------------------------
export const GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST = "GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST";
export const GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS = "GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS";
export const GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_ERROR = "GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_ERROR";

// ! -------------------------------- Top_Performing_Ads --------------------------------------------------
export const GET_TOP_PERFORMING_ADS_REQUEST = "GET_TOP_PERFORMING_ADS_REQUEST";
export const GET_TOP_PERFORMING_ADS_RESPONSE = "GET_TOP_PERFORMING_ADS_RESPONSE";
export const GET_TOP_PERFORMING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS = "GET_TOP_PERFORMING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS";
export const GET_TOP_PERFORMING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS = "GET_TOP_PERFORMING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS";
export const GET_TOP_PERFORMING_ADS_SELECTED_GROP_OPTIONS = "GET_TOP_PERFORMING_ADS_SELECTED_GROP_OPTIONS";

// ! -------------------------------- Top_Performing_Ads --------------------------------------------------
export const GET_NON_COVERTING_ADS_REQUEST = "GET_NON_PERFORMING_ADS_REQUEST";
export const GET_NON_COVERTING_ADS_RESPONSE = "GET_NON_PERFORMING_ADS_RESPONSE";
export const GET_NON_COVERTING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS = "GET_NON_COVERTING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS";
export const GET_NON_COVERTING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS = "GET_NON_COVERTING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS";
export const GET_NON_COVERTING_ADS_SELECTED_GROP_OPTIONS = "GET_NON_COVERTING_ADS_SELECTED_GROP_OPTIONS";


// ! -------------------------------- Google_Ads_Form_Submission_Date --------------------------------------------------
export const GOOGLE_ADS_FORM_SUBMISSION_DATE = "GOOGLE_ADS_FORM_SUBMISSION_DATE";
// ! -------------------------------- Meta_Ads_Form_Submission_Date --------------------------------------------------
export const META_ADS_FORM_SUBMISSION_DATE = "META_ADS_FORM_SUBMISSION_DATE";

//!------------------ facebook_Search_Box  -----------------------
export const FACEBOOK_SEARCH_BOX = "FACEBOOK_SEARCH_BOX";

//! -------------------------------- Conversion_Funnel_By_Insurance_company ------------------------------
export const GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_REQUEST = "GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_REQUEST";
export const GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_RESPONSE = "GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_RESPONSE";
export const GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_ERROR = "GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_ERROR";

//! -------------------------------- Schedule_First_Funnel_Report_Over_Time ------------------------------
export const GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_REQUEST = "GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_REQUEST";
export const GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_RESPONSE = "GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_RESPONSE";
export const GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_ERROR = "GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_ERROR";

//!-------------------------------Insurance_First_Funnel_Report_Over_Time --------------------------------
export const GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_REQUEST = "GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_REQUEST";
export const GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_RESPONSE = "GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_RESPONSE";
export const GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_ERROR = "GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_ERROR";


// ! -------------------------------- New_Patient_Paperwork_Submission_Rate------------------------------
export const NEW_PATIENT_PAPERWORK_SUBMISSION_RATE_REQUEST = "NEW_PATIENT_PAPERWORK_SUBMISSION_RATE_REQUEST";
export const GET_NEW_PATIENT_PAPERWORK_SUBMISSION_RATE_RESPONSE = "GET_NEW_PATIENT_PAPERWORK_SUBMISSION_RATE_RESPONSE";
export const GET_NEW_PATIENT_PAPERWORK_SUBMISSION_RATE_ERROR = "GET_NEW_PATIENT_PAPERWORK_SUBMISSION_RATE_ERROR";

// ! -------------------------------- Appointment Type------------------------------

export const GET_APPPOINTMENT_TYPE_REQUEST = "GET_APPPOINTMENT_TYPE_REQUEST";
export const GET_APPPOINTMENT_TYPE_RESPONSE = "GET_APPPOINTMENT_TYPE_RESPONSE";

// --------------------------------------------------------------------------------


export const ON_CHANGE_TEXT_CENTER = "ON_CHANGE_TEXT_CENTER"; // On change text center

export const COMMON_HANDLE_CHANGE = "COMMON_HANDLE_CHANGE"; // Common handle change

export const ON_APPLY_GLOBAL_DATE_FILTER = "ON_APPLY_GLOBAL_DATE_FILTER"; // Global date change

export const ON_APPLY_DATE_FILTER = "ON_APPLY_DATE_FILTER"; // Common handle change

export const CHANGE_CURRENT_PAGE = "CHANGE_CURRENT_PAGE"; // Chage page

// --------------------------------------------------------------------------------
